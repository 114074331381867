import React, { useContext, useState, MouseEvent } from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import { ScreenSizeContext } from "../contexts";

type HeaderDropdownItemProps = {
  readonly to?: string;
  readonly active: boolean;
  readonly label: string;
  readonly links: ReadonlyArray<Readonly<[string, string]>>;
};

function HeaderDropdownItem({
  to,
  label,
  active,
  links,
}: HeaderDropdownItemProps) {
  const screenSize = useContext(ScreenSizeContext);
  const isCompact = screenSize < 1200;
  const [isOpen, setOpen] = useState(false);

  // TODO: Make dropdown slide up and down
  const onIconClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isCompact) {
      setOpen(!isOpen);
    }
  };
  const onItemEnter = () => {
    if (!isCompact) {
      setOpen(true);
    }
  };
  const onItemLeave = () => {
    if (!isCompact) {
      setOpen(false);
    }
  };

  const linkContent = (
    <>
      {label}
      <button
        type="button"
        className={clsx(["icon", { "flip-icon": isOpen }])}
        onClick={onIconClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z" />
        </svg>
      </button>
    </>
  );

  return (
    <li
      className={clsx(["has-dropdown", { open: isOpen, active }])}
      onMouseEnter={onItemEnter}
      onMouseLeave={onItemLeave}
    >
      {to ? <Link to={to}>{linkContent}</Link> : <span>{linkContent}</span>}
      <ul className="dropdown" style={{ display: isOpen ? "block" : "none" }}>
        {links.map(([linkTo, linkLabel]) => (
          <li key={linkTo}>
            <Link to={linkTo}>{linkLabel}</Link>
          </li>
        ))}
      </ul>
    </li>
  );
}

export default HeaderDropdownItem;
