import React, { ReactElement, ReactNode, ReactNodeArray } from "react";
import { Link } from "gatsby";

type InternalOrExternalLinkProps = {
  readonly className?: string;
  readonly to: string;
  readonly children: ReactNode | ReactNodeArray;
};

export default function InternalOrExternalLink({
  className,
  to,
  children,
}: InternalOrExternalLinkProps): ReactElement {
  if (to.startsWith("http://") || to.startsWith("https://")) {
    return (
      <a
        className={className}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
}
