import React, { ReactElement } from "react";
import ReactCommonmark from "react-commonmark";

type MarkdownContentProps = {
  readonly children: string;
};

export default function MarkdownContent({
  children,
}: MarkdownContentProps): ReactElement {
  return <ReactCommonmark source={children} />;
}
