import React, { ReactNode, ReactElement } from "react";
import { Link } from "gatsby";
import MarkdownContent from "./markdown-content";

type HomeIntroSectionProps = {
  readonly intro: Readonly<{
    readonly title: string;
    readonly content: string;
  }>;
  readonly children: ReactNode;
  readonly callToActionButtons: ReadonlyArray<{
    readonly label: string;
    readonly url: string;
  }>;
};

function HomeIntroSection({
  children,
  intro: { title, content },
  callToActionButtons,
}: HomeIntroSectionProps): ReactElement<HomeIntroSectionProps> {
  return (
    <section className="home-intro">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 col-md-push-6">
            <div className="image-container">{children}</div>
          </div>
          <div className="col-xs-12 col-md-6 col-md-pull-6">
            <h1>{title}</h1>
            <MarkdownContent>{content}</MarkdownContent>
          </div>
        </div>
        <div className="home-intro-blocks">
          <div className="row home-intro-blocks-row">
            {callToActionButtons.map(({ label, url }) => (
              <div key={label} className="col-xs-12 col-sm-6 col-md-3">
                <div className="hib-block">
                  <h4 className="title">{label}</h4>
                  <Link className="full-box-link" to={url} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export { HomeIntroSectionProps };
export default HomeIntroSection;
