import React from "react";
import { Carousel } from "react-responsive-carousel";
import { GatsbyMarkdownFilesCollection, FluidSharpImageFile } from "../types";
import "react-responsive-carousel/lib/styles/carousel.min.css";

type GalleryProps = {
  readonly images: GatsbyMarkdownFilesCollection<{
    readonly title: string;
    readonly image: FluidSharpImageFile;
  }>;
};

function Gallery({ images }: GalleryProps) {
  return (
    <div className="gallery">
      <div className="row">
        <Carousel
          showArrows={false}
          showThumbs
          showStatus={false}
          showIndicators={false}
          swipeable
          useKeyboardArrows
          emulateTouch
        >
          {images.nodes.map((node) => (
            <div key={node.id}>
              <img
                src={
                  node.childMarkdownRemark.frontmatter.image.childImageSharp
                    .fluid.src
                }
                alt={node.childMarkdownRemark.frontmatter.title}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export { GalleryProps };
export default Gallery;
