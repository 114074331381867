import React from "react";
import Helmet from "react-helmet";

type SEOProps = {
  readonly title: string;
  readonly description?: string;
  readonly image: string;
  readonly meta?: ReadonlyArray<JSX.IntrinsicElements["meta"]>;
};

function SEO({ description, meta, image, title }: SEOProps) {
  // <!--[if lt IE 9]>
  // <script src="https://oss.maxcdn.com/libs/html5shiv/3.7.0/html5shiv.js"></script>
  // <script src="https://oss.maxcdn.com/libs/respond.js/1.3.0/respond.min.js"></script>
  // <![endif]-->
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:image",
          content: image,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        // {
        //   name: 'twitter:creator',
        //   content: site.siteMetadata.author,
        // },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
        ...(meta ?? []),
      ]}
    >
      <link
        rel="shortcut icon"
        href={require("../images/icons/favicon.ico")}
        type="image/x-icon"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: "",
};

export type { SEOProps };
export default SEO;
