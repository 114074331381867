import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import $ from "jquery";
import { Link } from "gatsby";
import Img from "gatsby-image";
import clsx from "clsx";
import HeaderDropdownItem from "./header-dropdown-item";
import InternalOrExternalLink from "./internal-or-external-link";
import { PathContext } from "../contexts";
import { FixedSharpImageFile } from "../types";

type MenuItem = {
  readonly to?: string;
  readonly label: string;
  readonly items?: ReadonlyArray<Readonly<[string, string]>>;
};

type HeaderProps = {
  readonly campName: string;
  readonly subHeading: string;
  readonly includeSafeEnvironment: boolean;
  readonly menuItems?: ReadonlyArray<MenuItem>;
  readonly partner?: {
    readonly logo: FixedSharpImageFile;
    readonly text: string;
  };
};

function Header({
  campName,
  subHeading,
  menuItems,
  includeSafeEnvironment,
  partner,
}: HeaderProps): ReactElement<HeaderProps> {
  useEffect(() => {
    const nav = $(".navigation");

    let screenSize = $(window).width() || 0;
    let navOffset = 0;

    function updateNavOffset() {
      const offset = nav.offset();
      navOffset = offset ? offset.top : 0;
    }
    updateNavOffset();

    $(window).resize(() => {
      screenSize = $(window).width() || 0;
      if ($(".navigation.fixed-nav").length === 0) {
        updateNavOffset();
      }
    });

    $(window).scroll(() => {
      if ($(".navigation.fixed-nav").length === 0) {
        updateNavOffset();
      }

      const currentScroll = $(window).scrollTop() || 0;
      const bodyPadding = screenSize >= 1200 ? "60px" : "53px";

      if (currentScroll > navOffset) {
        $("body").css("padding-top", bodyPadding);
        nav.addClass("fixed-nav");
      } else {
        nav.removeClass("fixed-nav");
        $("body").css("padding-top", "0");
      }
    });
  }, []);

  const [isResponsiveMenuOpen, setResponsiveMenuOpen] = useState(false);
  const onResponsiveMenuClick = useCallback(() => {
    const newResponsiveMenuOpen = !isResponsiveMenuOpen;
    setResponsiveMenuOpen(newResponsiveMenuOpen);
    // $(".responsive-menu-container").find(".responsive-menu").toggleClass('open');
    if (newResponsiveMenuOpen) {
      $(".navigation .nav").slideDown();
    } else {
      $(".navigation .nav").slideUp();
    }
  }, [isResponsiveMenuOpen]);

  const activePath = useContext(PathContext);

  return (
    <>
      <section className="responsive-top-menu">
        <div className="container">
          <a
            className="rev-color"
            href="https://www.ymcansw.org.au/safe-environment/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safety
          </a>
          <a
            className="rev-color"
            href="https://www.ymcansw.org.au"
            target="_blank"
            rel="noopener noreferrer"
          >
            The Y NSW
          </a>
          <a
            className="rev-color"
            href="https://careers.ymcansw.org.au/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Careers
          </a>
          <Link className="rev-color" to="/contact/">
            Contact
          </Link>
        </div>
      </section>

      <section className="site-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-7 logo-outer-container">
              <div className="logo-container">
                {partner && (
                  <Img
                    fixed={partner.logo.childImageSharp.fixed}
                    alt={partner.text}
                  />
                )}
                <Link to="/">
                  <img
                    className="logo"
                    src={require("../images/img/ymca-logo.svg")}
                    alt="YMCA NSW logo"
                  />
                  <span className="logo-text">
                    <strong>{campName}</strong>
                    <span className="sub-logo-text">{subHeading}</span>
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-xs-12 col-sm-5">
              <div className="nav-right">
                <ul className="top-menu-nav">
                  {includeSafeEnvironment && (
                    <li>
                      <a
                        href="https://www.ymcansw.org.au/safe-environment/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Safe Environment
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      href="https://www.ymcansw.org.au"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      The Y NSW
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://careers.ymcansw.org.au/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <nav id="navigation" className="navigation">
        <button
          type="button"
          className="responsive-menu-container"
          onClick={onResponsiveMenuClick}
        >
          <div className="container">
            <span className="text">Menu</span>
            <img
              className="responsive-nav-logo"
              src={require("../images/img/ymca-logo-white.svg")}
              alt="YMCA logo"
            />
            <div
              className={clsx([
                "responsive-menu",
                { open: isResponsiveMenuOpen },
              ])}
            >
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </button>

        {menuItems && (
          <div className="container">
            <ul className="nav">
              <li className="nav-logo">
                <Link to="/">
                  <img
                    src={require("../images/img/ymca-logo-white.svg")}
                    alt="YMCA logo"
                  />
                </Link>
              </li>
              {menuItems.map(({ to, label, items }) =>
                items ? (
                  <HeaderDropdownItem
                    key={label}
                    to={to}
                    label={label}
                    links={items}
                    active={
                      to === activePath ||
                      items.some(([linkTo]) => linkTo === activePath)
                    }
                  />
                ) : (
                  <li
                    key={to}
                    className={clsx({
                      active: to === activePath,
                    })}
                  >
                    {to ? (
                      <InternalOrExternalLink to={to}>
                        {label}
                      </InternalOrExternalLink>
                    ) : (
                      <span>{label}</span>
                    )}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </nav>
    </>
  );
}

export { HeaderProps };
export default Header;
