import React, { lazy, ReactElement, Suspense } from "react";
import isSsr from "../utils/isSsr";
import ArrowLink from "./arrow-link";
import { FixedSharpImageFile } from "../types";

const LazyOwlCarousel = lazy(() => import("react-owl-carousel"));

export type HomeSliderSectionBanner = {
  readonly title: string;
  readonly summary: string;
  readonly buttonText: string;
  readonly buttonUrl: string;
  readonly image: FixedSharpImageFile;
  readonly mobileImage: FixedSharpImageFile;
};

export type HomeSliderSectionProps = {
  readonly banners: ReadonlyArray<HomeSliderSectionBanner>;
};

export default function HomeSliderSection({
  banners,
}: HomeSliderSectionProps): ReactElement<HomeSliderSectionProps> {
  /* eslint-disable react/no-danger */
  return (
    <section className="home-slider">
      {!isSsr && (
        <Suspense fallback={<></>}>
          <LazyOwlCarousel
            id="home-slider"
            className="owl-carousel owl-theme"
            loop={false}
            nav={false}
            dots
            autoPlay
            autoplayTimeout={5000}
            smartSpeed={800}
            items={1}
            center
          >
            {banners.map((b) => (
              <div key={`${b.buttonUrl}-${b.title}`} className="item">
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                  /* SMALL - mobile image */
                  .home-hero1{
                    background: url(${b.mobileImage.childImageSharp.fixed.src}) no-repeat 50% 25%;
                    background-size: cover;
                  }                  
                  /* LARGE - desktop image > 768px */
                  @media (min-width: 768px) {
                    .home-hero1{
                      background: url(${b.image.childImageSharp.fixed.src}) no-repeat 100% 50%;
                      background-size: cover;
                    }
                  }
                `,
                  }}
                />
                <section className="home-hero home-hero1">
                  <span className="overlay" />
                  <div className="container">
                    <div className="hero-content">
                      <h1 className="title">{b.title}</h1>
                      <p className="subtext font-smooth">{b.summary}</p>
                      <ArrowLink to={b.buttonUrl}>{b.buttonText}</ArrowLink>
                    </div>
                  </div>
                </section>
              </div>
            ))}
          </LazyOwlCarousel>
        </Suspense>
      )}
    </section>
  );
  /* eslint-enable react/no-danger */
}
