import React, {
  ReactElement,
  ReactNode,
  ReactNodeArray,
  useContext,
} from "react";
import { Link } from "gatsby";
import clsx from "clsx";
import { PathContext } from "../contexts";
import ArrowIcon from "./arrow-icon";

type InThisSectionLinkProps = {
  readonly to: string;
  readonly children: string;
};

export function InThisSectionLink({
  to,
  children,
}: InThisSectionLinkProps): ReactElement {
  const path = useContext(PathContext);
  return (
    <li className={clsx({ active: path === to })}>
      <Link to={to}>
        {children} <ArrowIcon />
      </Link>
    </li>
  );
}

type InThisSectionButtonProps = {
  readonly to: string;
  readonly children: string;
};

export function InThisSectionButton({
  to,
  children,
}: InThisSectionButtonProps): ReactElement {
  return (
    <li className="button">
      <Link className="btn bg-red btn-medium btn-block" to={to}>
        {children}
      </Link>
    </li>
  );
}

type InThisSectionProps = {
  readonly children: ReactNode | ReactNodeArray;
};

export default function InThisSection({
  children,
}: InThisSectionProps): ReactElement {
  return (
    <div className="sidebar">
      <h3 className="title">In this section</h3>
      <ul className="sidebar-link-list">{children}</ul>
    </div>
  );
}
