import React from 'react'
import { FacebookNameContext, GoogleMapsKeyContext, PathContext } from '@ymcansw-camping/common'
import ensureEnv from "./src/utils/ensureEnv"
import { YouTubeChannelContext } from './src/contexts'

export function wrapRootElement ({ element }) {
  return (
    <YouTubeChannelContext.Provider
      value={{
        apiKey: ensureEnv("GATSBY_YOU_TUBE_API_KEY", process.env.GATSBY_YOU_TUBE_API_KEY)
      }}
    >
      <GoogleMapsKeyContext.Provider value={ensureEnv("GATSBY_GOOGLE_MAPS_KEY", process.env.GATSBY_GOOGLE_MAPS_KEY)}>
        <FacebookNameContext.Provider value={ensureEnv("GATSBY_FACEBOOK_NAME", process.env.GATSBY_FACEBOOK_NAME)}>
          {element}
        </FacebookNameContext.Provider>
      </GoogleMapsKeyContext.Provider>
    </YouTubeChannelContext.Provider>
  )
}

export function wrapPageElement ({ element, props: { path } }) {
  return <PathContext.Provider value={path}>{element}</PathContext.Provider>
}
