import { createContext } from "react";

type YouTubeChannel = {
  readonly channelName: string;
};

export const YouTubeChannelContext = createContext<YouTubeChannel | undefined>(
  undefined
);
export const PathContext = createContext<string | undefined>(undefined);
export const FacebookNameContext = createContext<string | undefined>(undefined);
export const ScreenSizeContext = createContext<number>(0);
export const GoogleMapsKeyContext = createContext<string | undefined>(
  undefined
);
