import React, { ReactElement } from "react";
import Img from "gatsby-image";
import { FixedSharpImageFile } from "../types";

type AccreditationsProps = {
  readonly campName: string;
  readonly atapLogo: FixedSharpImageFile;
  readonly acaLogo: FixedSharpImageFile;
  readonly safeguardLogo: FixedSharpImageFile;
};

export default function AccreditationsSection({
  campName,
  atapLogo,
  acaLogo,
  safeguardLogo,
}: AccreditationsProps): ReactElement<AccreditationsProps> {
  return (
    <section className="accreditations">
      <div className="container">
        <div className="content-heading text-center">
          <h2>Our accreditations</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <div className="accreditation-box">
              <div className="acc-logo-container">
                <Img
                  className="atap-logo"
                  fixed={atapLogo.childImageSharp.fixed}
                  alt="ATAP logo"
                />
              </div>
              <p>
                {campName} has ATAP (Australian Tourism Accreditation Program)
                accreditation. We commit to a high quality of service.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-4">
            <div className="accreditation-box">
              <div className="acc-logo-container">
                <Img
                  fixed={safeguardLogo.childImageSharp.fixed}
                  alt="Safeguarding Children logo"
                />
              </div>
              <p>
                The Y is accredited by the Australian Childhood Foundation (ACF)
                under the Safeguarding Children Program.
              </p>
            </div>
          </div>

          <div className="col-xs-12 col-sm-4">
            <div className="accreditation-box">
              <div className="acc-logo-container">
                <Img
                  fixed={acaLogo.childImageSharp.fixed}
                  alt="Australian Camps Association logo"
                />
              </div>
              <p>
                The &apos;not-for-profit&apos; Australian Camps Association
                (ACA) is the national peak body for residential camps and
                associated providers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
