import React from "react";

type InstagramBaseMedia = {
  readonly id: string;
  readonly permalink: string;
  readonly media_url: string;
  readonly caption: string;
};

type InstagramImageMedia = InstagramBaseMedia & {
  readonly media_type: "IMAGE" | "CAROUSEL_ALBUM"; // Probably more
};

type InstagramVideoMedia = InstagramBaseMedia & {
  readonly media_type: "VIDEO";
  readonly thumbnail_url: string;
};

type InstagramMedia = InstagramImageMedia | InstagramVideoMedia;

type InstagramResponse = {
  readonly data?: ReadonlyArray<InstagramMedia>;
};

function InstagramFeed() {
  return (
    <div className="social-box instagram-box">
      <div className="social-box-header">
        <span className="name">Instagram</span> <span>at</span>{" "}
        <a
          className="handle"
          href="https://www.instagram.com/ymcansw/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @ymcansw
        </a>
      </div>
    </div>
  );
}

export default InstagramFeed