import React, { ReactElement } from "react";
import InternalOrExternalLink from "./internal-or-external-link";
import ArrowIcon from "./arrow-icon";

type ArrowLinkProps = {
  readonly to: string;
  readonly children: string;
};

export default function ArrowLink({
  to,
  children,
}: ArrowLinkProps): ReactElement {
  return (
    <InternalOrExternalLink
      className="btn bg-red btn-medium with-icon icon-right with-hover"
      to={to}
    >
      {children} <ArrowIcon />
    </InternalOrExternalLink>
  );
}
