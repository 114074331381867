import React, { ReactElement } from "react";
import { FixedSharpImageFile } from "../types";
import ArrowLink from "./arrow-link";

type BannerHeaderProps = {
  readonly title: string;
  readonly image: FixedSharpImageFile;
  readonly showMakeAnEnquiry?: boolean;
};

export default function BannerHeader({
  title,
  image,
  showMakeAnEnquiry,
}: BannerHeaderProps): ReactElement {
  return (
    <section
      className="hero-small"
      style={{
        backgroundImage: `url(${image.childImageSharp.fixed.src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
      }}
    >
      <span className="overlay" />
      <div className="container">
        <h1 className="title">{title}</h1>
        {showMakeAnEnquiry && (
          <ArrowLink to="/contact/">Make an Enquiry</ArrowLink>
        )}
      </div>
    </section>
  );
}
