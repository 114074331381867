// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-community-connect-tsx": () => import("./../../../src/pages/community/connect.tsx" /* webpackChunkName: "component---src-pages-community-connect-tsx" */),
  "component---src-pages-community-index-tsx": () => import("./../../../src/pages/community/index.tsx" /* webpackChunkName: "component---src-pages-community-index-tsx" */),
  "component---src-pages-community-inspire-tsx": () => import("./../../../src/pages/community/inspire.tsx" /* webpackChunkName: "component---src-pages-community-inspire-tsx" */),
  "component---src-pages-community-upcoming-tsx": () => import("./../../../src/pages/community/upcoming.tsx" /* webpackChunkName: "component---src-pages-community-upcoming-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-camp-activities-tsx": () => import("./../../../src/pages/our-camp/activities.tsx" /* webpackChunkName: "component---src-pages-our-camp-activities-tsx" */),
  "component---src-pages-our-camp-index-tsx": () => import("./../../../src/pages/our-camp/index.tsx" /* webpackChunkName: "component---src-pages-our-camp-index-tsx" */),
  "component---src-pages-our-camp-our-team-tsx": () => import("./../../../src/pages/our-camp/our-team.tsx" /* webpackChunkName: "component---src-pages-our-camp-our-team-tsx" */),
  "component---src-pages-our-camp-virtual-tour-tsx": () => import("./../../../src/pages/our-camp/virtual-tour.tsx" /* webpackChunkName: "component---src-pages-our-camp-virtual-tour-tsx" */),
  "component---src-pages-private-bookings-tsx": () => import("./../../../src/pages/private-bookings.tsx" /* webpackChunkName: "component---src-pages-private-bookings-tsx" */),
  "component---src-pages-schools-index-tsx": () => import("./../../../src/pages/schools/index.tsx" /* webpackChunkName: "component---src-pages-schools-index-tsx" */),
  "component---src-pages-schools-intro-journey-tsx": () => import("./../../../src/pages/schools/intro-journey.tsx" /* webpackChunkName: "component---src-pages-schools-intro-journey-tsx" */),
  "component---src-pages-schools-journey-tsx": () => import("./../../../src/pages/schools/journey.tsx" /* webpackChunkName: "component---src-pages-schools-journey-tsx" */),
  "component---src-pages-schools-learning-framework-tsx": () => import("./../../../src/pages/schools/learning-framework.tsx" /* webpackChunkName: "component---src-pages-schools-learning-framework-tsx" */),
  "component---src-pages-schools-residential-tsx": () => import("./../../../src/pages/schools/residential.tsx" /* webpackChunkName: "component---src-pages-schools-residential-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-venue-hire-tsx": () => import("./../../../src/pages/venue-hire.tsx" /* webpackChunkName: "component---src-pages-venue-hire-tsx" */),
  "component---src-templates-general-page-tsx": () => import("./../../../src/templates/general-page.tsx" /* webpackChunkName: "component---src-templates-general-page-tsx" */)
}

