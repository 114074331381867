import React from "react";
import YouTubeFeed from "./you-tube-feed";
import FacebookFeed from "./facebook-feed";
import InstagramFeed from "./instagram-feed";

export default function SocialSection() {
  return (
    <section className="social">
      <div className="container">
        <div className="content-heading with-subheading text-center">
          <h2 className="title">
            Connect with us <span className="color-red">@YMCANSWCamping</span>
          </h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YouTubeFeed />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <FacebookFeed />
          </div>
          <div className="col-xs-12 col-md-4">
            <InstagramFeed />
          </div>
        </div>
      </div>
    </section>
  );
}
