import React, { useContext } from "react";
import { YouTubeChannelContext } from "../contexts";

function YouTubeFeed() {
  const { channelName } = useContext(
    YouTubeChannelContext
  )!;

  return (
    <div className="social-box youtube-box">
      <div className="social-box-header">
        <span className="name">Youtube</span> <span>at</span>{" "}
        <a
          className="handle"
          href={`https://www.youtube.com/user/${channelName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          @ymcasydney
        </a>
      </div>
    </div>
  );
}

export default YouTubeFeed;
