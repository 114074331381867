import React, { ReactNode, ReactNodeArray, useEffect, useState } from "react";
import $ from "jquery";
import isSsr from "../utils/isSsr";
import Header, { HeaderProps } from "./header";
import Footer, { FooterProps } from "./footer";
import SEO, { SEOProps } from "./seo";
import { ScreenSizeContext } from "../contexts";
import { Meta } from "../types";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../styles/vendor.css";
import "../styles/jquery.fancybox.min.css";
import "../styles/style.css";

type LayoutProps = {
  readonly campName: string;
  readonly subHeading: string;
  readonly includeSafeEnvironment: boolean;
  readonly metaImage: string;
  readonly meta: Meta;
  readonly headerMenuItems: HeaderProps["menuItems"];
  readonly partner?: HeaderProps["partner"];
  readonly footerColumns: FooterProps["columns"];
  readonly contactDetails: ReactNode | ReactNodeArray;
  readonly children: ReactNode | ReactNodeArray;
  readonly notice?: ReactNode | ReactNodeArray;
  readonly extraMeta?: SEOProps["meta"];
};

function Layout({
  campName,
  subHeading,
  includeSafeEnvironment,
  meta,
  metaImage,
  headerMenuItems,
  footerColumns,
  contactDetails,
  children,
  notice,
  partner,
  extraMeta,
}: LayoutProps) {
  const [screenSize, setScreenSize] = useState(isSsr ? 0 : window.innerWidth);
  useEffect(() => {
    const onResize = () => {
      setScreenSize(window.innerWidth);
    };
    $(window).resize(onResize);
    return () => {
      $(window).off("resize", onResize);
    };
  }, []);

  // const defaultTitle = `${campName} | The Y Outdoor Education and Camping`;

  return (
    <ScreenSizeContext.Provider value={screenSize}>
      <SEO
        title={`${meta.title} | YMCA Camping`}
        description={meta.description}
        image={metaImage}
        meta={extraMeta}
      />
      <div id="fb-root" />
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v5.0"
      />
      {notice && (
        <div className="notice">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">{notice}</div>
            </div>
          </div>
        </div>
      )}
      <Header
        partner={partner}
        menuItems={headerMenuItems}
        campName={campName}
        subHeading={subHeading}
        includeSafeEnvironment={includeSafeEnvironment}
      />
      {children}
      <Footer contactDetails={contactDetails} columns={footerColumns} />
    </ScreenSizeContext.Provider>
  );
}

export { LayoutProps };
export default Layout;
