import React, { useContext } from "react";
import { FacebookNameContext } from "../contexts";
import useFacebookUrl from "../hooks/useFacebookUrl";

export default function FacebookFeed() {
  const pageName = useContext(FacebookNameContext);
  const url = useFacebookUrl();

  return (
    <div className="social-box facebook-box">
      <div className="social-box-header">
        <span className="name">Facebook</span> <span>at</span>{" "}
        <a
          className="handle"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          @{pageName}
        </a>
      </div>
    </div>
  );
}
