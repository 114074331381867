import React, { ReactElement } from "react";
import clsx from "clsx";
import InternalOrExternalLink from "./internal-or-external-link";

type FooterBoldColumnLinksProps = {
  readonly size: "wide" | "normal" | "slim";
  readonly items: ReadonlyArray<Readonly<[string, string]>>;
};

function FooterColumnLinks({
  size,
  items,
}: FooterBoldColumnLinksProps): ReactElement<FooterBoldColumnLinksProps> {
  return (
    <div
      className={clsx([
        "col-xs-12",
        "col-sm-4",
        {
          "col-lg-2": size !== "wide",
          "col-lg-3": size === "wide",
          "col-lg-offset-1": size === "slim",
        },
      ])}
    >
      <div className="footer-links header-links">
        <ul className="nav">
          {items.map(([itemTo, itemLabel]) => (
            <li key={itemTo}>
              <InternalOrExternalLink to={itemTo}>
                {itemLabel}
              </InternalOrExternalLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default FooterColumnLinks;
